import React from 'react';
import { Datagrid, EditButton, DeleteButton, List, TextField, NumberField, BooleanField } from 'react-admin';

const PartnerList = (props: any) => (
    <List {...props} perPage={25} bulkActionButtons={false} sort={{ field: 'page', order: 'ASC' }}>
        <Datagrid>
            <TextField source="name" />
            <TextField source="url" />
            <BooleanField source="live" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default PartnerList;
