import React, {Component} from 'react';
import Header from "./components/header";
import './privacyPage.scss'
import {withRouter} from 'react-router-dom';
import Footer from "./components/footer";

class PrivacyPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"privacyPage"}>
                <Header nav={false}/>
                <div className={"privacyContainer"}>
                    <div className={"privacyTitle"}>Privacy</div>
                    <div className={"privacyText"}>
                        <p><strong>Persoonsgegevens&nbsp;</strong></p>
                        <p><strong>Beschrijving van het onderwerp en de duur van de verwerking:</strong></p>
                        <p>Verwerker verzamelt e-mail adressen en opt-ins van deelnemers aan de actie &ldquo;Bart&rsquo;s Wandelquiz actie&rsquo;&rsquo; gedurende de looptijd van de overeenkomst tussen partijen. Verwerker zal de persoonsgegevens uiterlijk 1 maand na uitlevering vernietigen.</p>
                        <p><strong>Beschrijving van de aard en het doel van de verwerking:&nbsp;</strong></p>
                        <p>Ten behoeve van marketingdoeleinden van Opdrachtgever.</p>
                        <p><strong>Beschrijving van het soort persoonsgegevens dat wordt verwerkt:</strong></p>
                        <p>E-mail adres inclusief opt-in.&nbsp;</p>
                        <p><strong>Beschrijving van de categorie&euml;n van betrokkenen:</strong></p>
                        <p>Deelnemers aan voornoemde actie.</p>
                        <p><strong>Contactgegevens:</strong></p>
                        <p><strong>Opdrachtgever:</strong></p>
                        <p><strong>Bart&rsquo;s Retail B.V.</strong> - H.A.F. Schalkwijk</p>
                        <p><strong>Verwerker:</strong></p><p><strong>Activents</strong> - Bastaan Prijt</p>
                        <p>Contact: bastiaan@activents.nl </p>
                        <p>Op het gebruik van de Bart's Wandelquiz-app is <a href={"https://activents.caught.nu/privacy"} target={"_blank"}>deze Privacy Policy</a> van toepassing.</p>
                    </div>
                </div>
                <Footer className={"footer"} links={false} />
            </div>
        )
    }
}

export default withRouter(PrivacyPage);
