import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({component: Component, authorized = true, ...rest}) => {
    if(!authorized) {
        return <Redirect to="/login" />;
    } else {
        return <Route {...rest} render={props => <Component {...props} {...rest} />} />
    }
};

export default PrivateRoute;
