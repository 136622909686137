import React, {FC} from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    ImageInput,
    ImageField,
    FileInput,
    FileField,
    TextInput,
    TextField,
    DateField,
    NumberInput,
    useTranslate,
    ReferenceArrayInput,
    SelectArrayInput,
    FunctionField,
    Toolbar,
    SaveButton,
    Button,
    Show,
    BooleanField,
    ChipField,
    ReferenceField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import {FieldProps, Order} from '../types';
import firebase from "firebase";
import {useRecordContext} from "ra-core";
import {Typography} from "@material-ui/core";
import Swal from "sweetalert2";
import Chip from "@material-ui/core/Chip";
import {EMULATE, ENV, FUNCTIONS_BASE_URL} from "../../config";

const OrderTitle: FC<FieldProps<Order>> = ({record}) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.orders.name', {smart_count: 1})} &quot;
            {record.id}&quot;
        </span>
    ) : null;
};

const createCaughtGroups = (id) => {
    // @ts-ignore
    Swal.fire({
        title: "New codes are being generated",
        text: "Please give us a sec (or two...)",
        iconColor: '#cb007c',
        showConfirmButton: false,
        closeOnClickOutside: false,
    });
    Swal.showLoading();

    const recreateCaughtGroups = firebase.functions().httpsCallable('recreateCaughtGroups');
    recreateCaughtGroups({
        orderId: id
    })
        .then((result) => {
            // @ts-ignore
            Swal.fire({
                title: "Generating",
                text: "Generating new codes for you. Refresh this page to download once they are done.",
                iconColor: '#cb007c',
                icon: 'success',
                showConfirmButton: true,
                showCancelButton: false,
                closeOnClickOutside: false,
            }).then((result) => {
                window.location.reload();
            });
        }).catch((error) => {
        // @ts-ignore
        Swal.fire({
            title: "Something went wrong...",
            text: error,
            iconColor: '#cb007c',
            showConfirmButton: false,
            closeOnClickOutside: false,
        });
    });
};

const resendEmail = (id) => {
    // @ts-ignore
    Swal.fire({
        title: "A confirmation will be sent.",
        text: "Please give us a moment",
        iconColor: '#cb007c',
        showConfirmButton: false,
        closeOnClickOutside: false,
    });
    Swal.showLoading();

    const resendConfirmation = firebase.functions().httpsCallable('resendConfirmation');
    resendConfirmation({
        orderId: id
    })
        .then((result) => {
            // @ts-ignore
            Swal.fire({
                title: "The E-mail has been sent.",
                iconColor: '#cb007c',
                icon: 'success',
                showConfirmButton: false,
                closeOnClickOutside: false,
            });
        }).catch((error) => {
        // @ts-ignore
        Swal.fire({
            title: "Something when wrong...",
            text: error,
            iconColor: '#cb007c',
            showConfirmButton: false,
            closeOnClickOutside: false,
        });
    });
};


const OrderEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton/>
        {/*{props.record && props.record.caughtGroups && props.record.caughtGroups.length > 0 ?*/}
        {/*    <Button label={"Bevestiging opnieuw versturen"} onClick={() => resendEmail(props.record.id)}/> : null}*/}
    </Toolbar>
);

const CaughtData = () => {
    const record = useRecordContext();
    if (record && (!record.caughtGroups || record.caughtGroups.length === 0) && !record.generating) {
        return (
            <Button onClick={() => {
                if(record.paymentId) {
                    createCaughtGroups(record.id);
                } else {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "Codes will be generated without a Stripe transaction being paid.",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, generate!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            createCaughtGroups(record.id);
                        }
                    });
                }
            }} label="Generate Caught codes!"/>
        )
    } else if (!record.generating) {
        return renderDownloadButtons(record);
    } else {
        return <span>We are generating your codes - please refresh later</span>;
    }
};

const getOrderStatus = (order) => {
    if (order.caughtGroups?.length > 0) {
        return 'Complete';
    } else if (order.paymentId) {
        return 'Paid';
    } else if (order.generating) {
        return 'Generating Codes';
    } else {
        return 'Pending';
    }
}

const renderDownloadButtons = (order) => {
    const auth = firebase.auth();
    const {currentUser} = auth;
    if (currentUser && order.caughtGroups && order.caughtGroups.length > 0) {
        return (
            <span style={{display: 'flex', flexDirection: 'row'}}>
                <Chip color={"primary"} label={"Download PDF"} onClick={() => {
                    currentUser.getIdToken(true).then((token) => {
                        let baseUrl = FUNCTIONS_BASE_URL[ENV];
                        if (EMULATE.func) {
                            baseUrl = FUNCTIONS_BASE_URL['dev'];
                        }
                        window.open(`${baseUrl}/generateOrderPDF?orderId=${order.id}&token=${token}`, '_blank');
                    });
                }} style={{marginLeft: 0}}/>
                    <Chip color={"primary"} label={"Download CSV"} onClick={() => {
                        currentUser.getIdToken(true).then((token) => {
                            let baseUrl = FUNCTIONS_BASE_URL[ENV];
                            if (EMULATE.func) {
                                baseUrl = FUNCTIONS_BASE_URL['dev'];
                            }
                            window.open(`${baseUrl}/generateOrderSheet?orderId=${order.id}&token=${token}`, '_blank');
                        });
                    }} style={{marginLeft: 10}}/>
                    <Chip color={"primary"} label={"Download XLS"} onClick={() => {
                        currentUser.getIdToken(true).then((token) => {
                            let baseUrl = FUNCTIONS_BASE_URL[ENV];
                            if (EMULATE.func) {
                                baseUrl = FUNCTIONS_BASE_URL['dev'];
                            }
                            window.open(`${baseUrl}/generateOrderSheet?orderId=${order.id}&token=${token}&type=xls`, '_blank');
                        });
                    }} style={{marginLeft: 10}}/>
                    </span>
        );
    }
    return null;
}

const OrderEdit = (props: any) => (
    <Edit title={<OrderTitle/>} {...props}>
        <SimpleForm toolbar={<OrderEditToolbar/>} redirect="edit">
            <TextField label="Order ID" source="id"/>
            <TextField label="No. of cdes" source="numberOfCodes"/>
            <TextField label="Price p/Code" source="pricePerCode"/>
            <TextField label="Value" source="transactionValue"/>
            <FunctionField label="Paid" render={record => record.paymentId ? "Yes" : "No"}/>
            <FunctionField label="Codes generated"
                           render={record => record.caughtGroups && record.caughtGroups.length > 0 ? "Yes" : "No"}/>
            <FunctionField label="Status" render={record => getOrderStatus(record)}/>
            <TextField label="Stripe ID" source="paymentId"/>
            <CaughtData />
        </SimpleForm>
    </Edit>
);

export default OrderEdit;
