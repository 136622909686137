import React, {FC, useCallback} from 'react';
import firebase from 'firebase';
import {
    Datagrid,
    Create,
    SimpleForm,
    BooleanInput,
    TextInput,
    useTranslate,
    ReferenceArrayInput,
    SelectArrayInput,
    Toolbar,
    SaveButton,
    useMutation,
    useRedirect,
    useNotify,
    ImageInput,
    ImageField, TextField, NumberInput, SelectInput
} from 'react-admin';

import {FieldProps, Category} from '../types';

const FileTitle: FC<FieldProps<Category>> = ({record}) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.faq.name', {smart_count: 1})} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

const FileEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

const validateUserCreation = async (values) => {
    const errors = {} as any;
    if (values.name < 5) {
        errors.name = 'Name is too short!';
    }
    return errors;
}

const PartnerCreate = (props: any) => {
    const [mutate] = useMutation();
    const redirect = useRedirect();
    const notify = useNotify();
    const save = useCallback(
        async (values) => {
            const errors = {} as any;
            const {name, email, password, min_order_size, max_order_size} = values;
            if (name.length < 5) {
                errors.name = 'Name is too short';
                return errors;
            }

            if (email.length < 5) {
                errors.email = 'Invalid E-mail';
                return errors;
            }
            if (password.length < 8) {
                errors.password = 'Password too short';
                return errors;
            }

            if(max_order_size < 1) {
                errors.min_order_size = 'Min order size cannot be smaller than 1';
            }

            if(max_order_size > 150) {
                errors.max_order_size = 'Max order size cannot be larger than 150';
            }

            if(min_order_size > max_order_size) {
                errors.min_order_size = 'Min order size cannot be larger than max order size';
                return errors;
            }

            // enable to do function based check if email exists. Is also executed in addPartnerUser function
            // const checkEmailIsInUse = firebase.functions().httpsCallable('checkEmailIsInUse');
            // const { data } = await checkEmailIsInUse({email});
            // const { emailIsInUse } = data;
            // if(emailIsInUse) {
            //     errors.email = 'E-mail already in use';
            //     return errors;
            // }

            const addPartnerUser = firebase.functions().httpsCallable('addPartnerUser');

            let userId = null;
            try {
                const {data} = await addPartnerUser({email, name, password}) as any;
                const {error, code, user} = data;
                if (error) {
                    if (code && code === 7) {
                        errors.email = data.error;
                        return errors;
                    }
                } else if (user) {
                    userId = user;
                    notify(`User added ${name} (${email})`);
                }
            } catch (e) {
                console.log(e);
                errors.email = 'Error creating account';
                return errors;
            }

            const customizedId = {...values, id: userId};

            try {
                await mutate({
                    type: 'create',
                    resource: 'partners',
                    payload: {data: customizedId},
                }, {
                    returnPromise: true,
                    onSuccess: (rv) => {
                        const { data } = rv;
                        const { id } = data;
                        redirect(`/partners/${id}`);
                    },
                    onFailure: (error) => {
                        console.log('err', error);
                    },
                });
            } catch (error) {
                console.log(error);
                if (error?.body?.errors) {
                    return error.body.errors;
                }
            }
        },
        [mutate],
    );
    return (
        <Create title={<FileTitle/>} {...props}>
            <SimpleForm toolbar={<FileEditToolbar/>} save={save}>
                <TextInput fullWidth={true} label="Name" source="name"/>
                <TextInput fullWidth={true} label="E-mail" source="email"/>
                <TextInput fullWidth={true} label="Password" source="password"/>
                <NumberInput source="min_order_size" step={1} min={1} />
                <NumberInput source="max_order_size" step={1} max={150} />
                <SelectInput source="code_validity" multiple={false} choices={[{
                    id: '6',
                    name: '6 Months',
                }, {
                    id: '12',
                    name: '12 Months',
                }]} initialValue={6} />
                <ImageInput label="Logo" source="logo" accept="image/png, image/jpeg" placeholder={<p>Drop your logo (JPG or PNG) here</p>}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <BooleanInput label="Charge VAT" source="chargeVAT"/>
                <BooleanInput label="Live" source="live"/>
            </SimpleForm>
        </Create>
    );
};

export default PartnerCreate;
