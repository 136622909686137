// export const DB_CONFIG = {
//     apiKey: "AIzaSyCaDzZ5IQJwvGUhQjGNlahQl_vYpBq6G10",
//     authDomain: "caught-test.firebaseapp.com",
//     projectId: "caught-test",
//     storageBucket: "caught-test.appspot.com",
//     messagingSenderId: "267554819948",
//     appId: "1:267554819948:web:fe5df3fc511dab9c47ed49"
// };

// PROD - ERGO
export const DB_CONFIG = {
    apiKey: "AIzaSyCaDzZ5IQJwvGUhQjGNlahQl_vYpBq6G10",
    authDomain: "escape-room-go-shop.firebaseapp.com",
    projectId: "escape-room-go-shop",
    storageBucket: "escape-room-go-shop.appspot.com",
    messagingSenderId: "733405324707",
    appId: "1:733405324707:web:f4f4705c0563f42297a616"
};

export let EMULATE = {
    db: false,
    func: false,
    auth: false,
};

export const ENV = 'prod';

export const STRIPE_KEY = {
    dev: {
        pk: "pk_test_51JKljEK2xdmLgkzKOH8tm1s34ko3rHap4DY6u3qTjf2tuyq6RXK7Brcs0a39CQmTWVaGrtQNVINUGadURFS8A7t700RIQ8Fvhi",
    },
    test: {
        pk: "pk_test_51JKljEK2xdmLgkzKOH8tm1s34ko3rHap4DY6u3qTjf2tuyq6RXK7Brcs0a39CQmTWVaGrtQNVINUGadURFS8A7t700RIQ8Fvhi",
    },
    prod: {
        pk: "pk_live_51JKljEK2xdmLgkzKgLlD5zMXdrmqfeBavPYQ6BP4XzY4cx02uYWnoMIJGaOmU4igbOOFU4HLpkims9tg9jjT8ApG00Q0owb0nO",
    },
}

export const FUNCTIONS_BASE_URL = {
    dev: 'http://localhost:5001/escape-room-go-shop/us-central1',
    test: 'https://us-central1-escape-room-go-shop.cloudfunctions.net',
    prod: 'https://us-central1-escape-room-go-shop.cloudfunctions.net',
}

export const APP_URLS = {
    google: "https://play.google.com/store/apps/details?id=nu.caught.escaperoomgo",
    apple: "https://apps.apple.com/gb/app/escape-room-go/id1570086848",
}
