import React from 'react';
import { Admin, Resource } from 'react-admin';
import {
    FirebaseAuthProvider,
    FirebaseDataProvider,
    FirebaseRealTimeSaga,
} from 'react-admin-firebase';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import { DB_CONFIG } from '../config.js';

import Categories from './Categories';
import Partners from './Partners';
import Games from './Games';
import Orders from './Orders';
import Faq from './Faq';
import { Dashboard } from './Dashboard';
import { Layout } from './Layout';
import './adminPage.scss'

const AdminPage = ({auth}) => {
    const i18nProvider = polyglotI18nProvider(locale => {

        // Always fallback on english
        return englishMessages;
    }, 'en');

    if(auth && auth.currentUser && auth.currentUser) {
        const { email } = auth.currentUser;
        if (email && !(email.match('.*@caught[.]nu$') || email.match('.*@bewilderbox[.]co.uk$'))) {
            // user is NOT an admin
            auth.signOut();
        }
    }
    const options = {};

    const dataProvider = FirebaseDataProvider(DB_CONFIG, options);
    const authProvider = FirebaseAuthProvider(DB_CONFIG, options);

    return(
        <div className={"adminPage"}>
            <Admin
                dashboard={Dashboard}
                dataProvider={dataProvider}
                authProvider={authProvider}
                layout={Layout}
                i18nProvider={i18nProvider}>
                <Resource name="orders" {...Orders} />
                <Resource name="categories" {...Categories} />
                <Resource name="partners" {...Partners} />
                <Resource name="games" {...Games} />
                <Resource name="faq" {...Faq} />
            </Admin>
        </div>
    )
};

export default AdminPage;
