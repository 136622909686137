import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

import GameCreate from './GameCreate';
import GameList from './GameList';
import GameEdit from './GameEdit';

export default {
    create: GameCreate,
    list: GameList,
    edit: GameEdit,
    icon: SportsEsportsIcon,
};
