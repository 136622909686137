import React, {Component} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Header from "./components/header";
import parse from 'html-react-parser';
import './faqPage.scss'
import Footer from "./components/footer";
import firebase from "firebase";

class FaqPage extends Component {
    constructor(props) {
        super(props);

        this.state = {faq: []}
    }

    fetchFaq = () => {
        const db = firebase.firestore();
        db.collection("faq").where("live", "==", true).orderBy("sort").get().then((querySnapshot) => {
            let faq = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                faq.push(doc.data());
            });
            this.setState({faq})
        }).catch((error) => {
            console.log("Error getting faq: ", error);
        });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.fetchFaq();
    }

    render() {
        const { faq } = this.state;

        return (
            <div className={"faqPage"}>
                <Header/>
                <div className={"faqContainer"}>
                    <div className={"faqTitle"}>Frequently Asked Questions</div>
                    <Accordion allowMultipleExpanded>
                        {faq.map((faqItem) => (
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {faqItem.question}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                        {parse(faqItem.answer)}
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
                <Footer className={"footer"} />
            </div>
        )
    }
}

export default FaqPage;
