import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import firebase from 'firebase';

class Logout extends Component {
    constructor(props) {
        super(props);
        firebase.auth().signOut().then(() => {
            this.props.history.push('/');
            setTimeout(() => {
                window.location.reload(true);
            }, 500);
        }).catch(() => {
            this.props.history.push('/');
        });
    }

    render() {
        return null;
    }
}

export default withRouter(Logout);
