import React, {Component} from 'react';
import Header from "./components/header";
import './disclaimerPage.scss'
import {withRouter} from 'react-router-dom';
import Footer from "./components/footer";

class DisclaimerPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"disclaimerPage"}>
                <Header nav={false}/>
                <div className={"disclaimerContainer"}>
                    <div className={"disclaimerTitle"}>Algemene voorwaarden</div>
                    <div className={"disclaimerText"}>
                        Spelvoorwaarden voor de activiteit: “Bart’s Wandelquiz”
                        De actie is een consumentenactie van ACTIVENTS, gevestigd en kantoorhoudend aan de Van
                        Brammenstraat 23 te (3813 PM) Amersfoort, KvK nummer 74699083, in samenwerking met BART’S
                        RETAIL B.V., gevestigd en kantoorhoudende aan de Papendorpseweg 97, gebouw B 5e

                        , te (3528 BJ)

                        Utrecht, KvK nummer 64235039.
                        <p> <b>Looptijd:</b> De Actie loopt van 7 juni 2021 t/m 29 augustus 2021.</p>
                        <p>De deelnemer is verplicht tot het verschaffen van correcte, actuele en complete informatie bij de
                        deelname aan het spel. De Aanbieder kan een winnaar verzoeken om voor uitreiking van de prijs
                        bewijs te leveren van zijn/haar identiteit en een geldige inschrijving op het door de deelnemer
                            opgegeven contactgegevens.</p>
                        <p>Over de spelinhoud, de uitslag van het spel en de toekenning van prijzen kan niet worden
                            gecorrespondeerd.</p>
                        <p>De prijzen zijn persoonsgebonden en staan op naam van de winnaar. De prijzen zijn niet
                        overdraagbaar, inwisselbaar voor geld of voor andere producten of diensten. Bij weigering of niet
                        aanvaarding van de prijs of de aan de prijs verbonden voorwaarden, zal de prijs niet worden
                            uitgekeerd en is de Aanbieder gerechtigd een andere winnaar te selecteren.</p>
                        <p>De prijzen worden toegekend in de staat waarin ze zich bevinden. De Aanbieder is niet
                        verantwoordelijk voor enige zichtbare of verborgen gebreken aan de prijzen noch voor eventuele
                            schade bij de levering (verzending) van de prijs.</p>
                        <p>De afhandeling van de levering van de prijzen gebeurt door de Aanbieder of een ander daartoe
                            aangestelde derde partij.</p>
                        <p>De Aanbieder is niet verantwoordelijk voor eventuele gebreken van post- of verzendingsbedrijven
                        (bijvoorbeeld vertraging, staking, beschadiging of verlies) bij de levering van de prijzen. In het geval
                        de winnaar een aangetekend verzonden prijs niet in ontvangst kan nemen bij bezorging, zal de
                        winnaar de prijs op een later tijdstip afhalen (bijvoorbeeld bij postkantoor of postagentschap e.d.).
                        De Aanbieder kan niet aansprakelijk gesteld worden, indien de prijs niet wordt afgehaald door de
                        winnaar of door een onbevoegd persoon in ontvangst is genomen. Ook niet in geval er geen
                        notificatie of berichtgeving in enigerlei vorm door het post- of verzendbedrijven is achtergelaten.
                        </p><p>Indien de prijs bestaat uit toegangskaarten voor motorsport-, watersportactiviteiten of gelijksoortige
                        dan wel andersoortige evenementen, kan er bij afgelasting geen aanspraak gemaakt worden op
                            enige vorm van compensatie.</p>
                        <p>De winnaar kan tot uiterlijk drie maanden na bekendmaking aanspraak maken op uitreiking van de
                            prijs. Na deze periode vervalt de aanspraak op de prijs automatisch.</p>
                        <p>De winnaars van de prijzen zullen op verzoek van de Aanbieder medewerking verlenen aan
                        promotionele activiteiten van de Aanbieder voor zover dit in redelijkheid van hen verlangd mag
                        worden, waaronder begrepen publiciteit ten aanzien van de prijsuitreiking. Voor de verleende
                            medewerking aan promotionele activiteiten zal geen financiële vergoeding worden betaald.</p>
                        <p>De deelnemer geeft toestemming tot de verwerking van persoonsgegevens in verband met de
                        deelname aan het spel. De Aanbieder mag de persoonsgegevens opnemen in haar gegevensbestand,
                            dat vertrouwelijk en conform de Wet bescherming persoonsgegevens zal worden behandeld.</p>

                        <p>Door het meedoen aan de actie ga je akkoord met de verwerking van jouw persoonsgegevens en
                        door het instemmen met het verstrekken van jouw e-mail adres aan Bakker Bart geef je expliciet
                            toestemming om door Bakker Bart benaderd te worden.</p>
                        <p>Activents en Bakker Bart behouden zich het recht voor de actievoorwaarden te wijzigen en de Actie
                            aan te passen of vroegtijdig te beëindigen.</p>
                        <p>De persoonsgegevens kunnen worden gebruikt om uitvoering te geven aan het spel en de uitreiking
                        van de prijzen. In dat kader is de Aanbieder gerechtigd om de persoonsgegevens te verstrekken aan
                        derde partijen, die betrokken zijn bij het betreffende spel (waaronder begrepen dealers en/of
                        sponsors). Bovendien verleent de deelnemer toestemming om hem/haar op de hoogte te houden
                            van producten en/of diensten van de Aanbieder.</p>
                        <p>Gebruik van de ter beschikking gestelde prijzen is voor rekening en risico van de winnaars. De
                        Aanbieder kan niet aansprakelijk worden gesteld op grond van de Algemene en/of Specifieke
                            spelvoorwaarden voor het gebruik van de prijzen door de winnaars.</p>
                       <p> De Aanbieder is op geen enkele wijze aansprakelijk voor eventuele schade die voortvloeit uit de
                        deelname aan het spel noch voor eventuele technische storingen, gebreken of vertragingen met
                           betrekking tot de deelname aan het spel of de aanwijzing van de winnaar.</p>
                        <p>De Aanbieder is op geen enkele wijze aansprakelijk voor mogelijke problemen of gebreken die
                        voortvloeien uit het gebruik van de website en/of het downloaden van welke componenten dan ook
                            met betrekking tot de spellen.</p>
                       <p> De Aanbieder is gerechtigd deelnemers te diskwalificeren als zij van mening is dat de deelnemers niet
                        conform de Algemene en/of Specifieke spelvoorwaarden handelen dan wel indien de deelnemers
                        zich anderszins op frauduleuze wijze toegang verschaffen tot het spel of het spelverloop op
                           oneerlijke wijze beïnvloeden.</p>
                        <p>De Aanbieder is gerechtigd naar eigen inzicht en zonder voorafgaande kennisgeving deze
                        Actievoorwaarden alsmede de Specifieke spelvoorwaarden gedurende de looptijd van het spel
                        (Actieperiode) te wijzigen of aan te passen, dan wel zonder opgave van reden het spel te staken, te
                        wijzigen of aan te passen vanwege haar moverende redenen, zonder dat de Aanbieder daardoor op
                        enigerlei wijze tot vergoeding van schade is gehouden jegens de deelnemer. Wijziging of aanpassing
                        van het spel, zal door de Aanbieder op passende wijze publiekelijk (bijvoorbeeld via haar site) bekend
                            worden gemaakt.</p>
                        <p>De applicatie is beschikbaar in de Apple store en Google Playstore. Apple en Google zijn echter niet verbonden aan het spel.</p>
                        <p>Indien één of meer bepalingen van de Algemene en/of Specifieke spelvoorwaarden nietig of ongeldig
                        zijn, dan tast dit niet de geldigheid van de overige bepalingen van de Algemene en/of Specifieke
                            spelvoorwaarden aan.</p>
                        <p>Bij deelname van het spel verstrekte persoonsgegevens worden behandeld conform de Algemene
                            Verordening Gegevensbescherming (AVG).</p>
                        <p>Door het meedoen aan de actie ga je akkoord met de verwerking van jouw persoonsgegevens en
                        door het instemmen met het verstrekken van jouw e-mailadres aan Bakker Bart geef je expliciet
                            toestemming om door Bakker Bart gemaild of gebeld te worden.</p>
                        <p>Activents en Bakker Bart behouden zich het recht voor de actievoorwaarden te wijzigen en de Actie
                            aan te passen of vroegtijdig te beëindigen.</p>

                        <p>
                        Eventuele vragen, klachten of opmerkingen over de spelen kunnen aan de communicatie afdeling
                            van Bart’s Wandelquiz via het mailadres: <a href={"mailto:info@bartswandelquiz.nl"}>info@bartswandelquiz.nl</a></p>
                    </div>
                </div>
                <Footer className={"footer"} links={false} />
            </div>
        )
    }
}

export default withRouter(DisclaimerPage);
