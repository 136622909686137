import './App.scss';
import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import firebase from "firebase";

import HomePage from "./pages/homePage";
import LoginPage from "./pages/loginPage";
import OrdersOverviewPage from "./pages/ordersOverviewPage";
import FaqPage from "./pages/faqPage";
import {DB_CONFIG, EMULATE } from './config.js';
import ScrollToTop from "./pages/components/scrollToTop";
import SummaryPage from "./pages/summaryPage";
import DisclaimerPage from "./pages/disclaimerPage";
import ComingSoon from "./pages/comingSoon";
import AdminPage from "./admin/adminPage";
import PrivacyPage from "./pages/privacyPage";
import OrderPage from "./pages/orderPage";
import Logout from "./pages/logout";
import PublicRoute from "./router/publicRoute";
import PrivateRoute from "./router/privateRoute";

firebase.initializeApp(DB_CONFIG);

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authenticated: false,
            initializingAuth: true,
        }
        this.db = firebase.firestore();
        this.auth = firebase.auth();
        this.func = firebase.functions();

        const { db, func, auth } = EMULATE;
        if (window.location.hostname === "localhost") {
            if(db) {
                this.db.useEmulator("localhost", 8080);
            }
            if(func) {
                console.log('Use emulator');
                this.func.useEmulator('localhost', 5001);
            }
            if(auth) {
                this.auth.useEmulator("http://localhost:9099");
            }
        }
    }

    componentDidMount = () => {
        //this.fetchGames();
        const { auth } = this;
        const { authenticated, initializingAuth } = this.state;
        auth.onAuthStateChanged(user => {
           if (!user && !authenticated && !initializingAuth) {
                const {history} = this.props;
                history.push('/login');
            } else if (user && initializingAuth) {
                this.setState({
                    authenticated: true,
                    initializingAuth: false,
                })
            } else {
                this.setState({
                    authenticated: false,
                    initializingAuth: false,
                });
            }
        });
    };

    render() {
        const { authenticated, initializingAuth} = this.state;

        const authorized = initializingAuth || (!initializingAuth && authenticated);

        return (
            <div className="App">
                <Router>
                    {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                    <ScrollToTop>
                        <Switch>
                            <Redirect exact from="/" to="/games" />
                            <PublicRoute path="/login"
                                         restricted={true}
                                         authorized={authorized}
                                         auth={this.auth}
                                         component={LoginPage} />
                            {/* TODO: Replace all routes that need auth, db or firestore with a render that passes it along as props*/}
                            <PublicRoute path="/logout" component={Logout}/>
                            <PrivateRoute path="/games"
                                          authorized={authorized}
                                          auth={this.auth}
                                          db={this.db}
                                          component={OrderPage}
                            />
                            <PrivateRoute path="/game/:gameId"
                                          authorized={authorized}
                                          auth={this.auth}
                                          db={this.db}
                                          func={this.func}
                                          component={OrderPage}
                            />
                            <PrivateRoute path="/your-orders"
                                          authorized={authorized}
                                          auth={this.auth}
                                          db={this.db}
                                          func={this.func}
                                          component={OrdersOverviewPage}
                            />
                            <PrivateRoute path="/faq"
                                          authorized={authorized}
                                          component={FaqPage}
                            />
                            <PrivateRoute path="/summary/:orderId"
                                          authorized={authorized}
                                          db={this.db}
                                          func={this.func}
                                          auth={this.auth}
                                          component={SummaryPage}
                            />
                            <PublicRoute path="/support"
                                         component={DisclaimerPage} />
                            <PublicRoute path="/admin"
                                         auth={this.auth}
                                         component={AdminPage} />
                            <PublicRoute path="/privacy"
                                         component={PrivacyPage} />
                        </Switch>
                    </ScrollToTop>
                </Router>
            </div>
        );
    }
}

export default App;
