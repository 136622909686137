import React, {Component} from 'react';
import firebase from 'firebase';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Header from "./components/header";
import {where, findWhere} from 'underscore';
import './orderPage.scss'
import Footer from "./components/footer";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import {loadStripe} from '@stripe/stripe-js/pure'
import { STRIPE_KEY, ENV } from "../config";

const STRIPE_PK = STRIPE_KEY[ENV].pk;

class OrderPage extends Component {
    constructor(props) {
        super(props);

        const stripePromise = loadStripe(STRIPE_PK);
        stripePromise.then((result) => {
            this.stripe = result;
        });


        this.state = {
            error: null,
            games: [],
            game: null,
            numberOfCodes: 1,
            minOrderSize: 1,
            maxOrderSize: 150,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!this.state.game && !prevState.game) || this.state.game !== prevState.game || (this.state.game && prevState.game && this.state.game.id !== prevState.game.id)) {
            this.setCurrentGameToState();
        }

        const {match: {params}} = this.props;
        if (this.state.game && !params?.gameId) {
            this.setState({
                game: null,
            });
        }
    }

    fetchGames = async () => {
        const {auth, db} = this.props;
        const user = auth.currentUser;
        if (user !== null) {
            const partner = await db.collection('partners')
                .doc(user.uid).get();

            const games = [];

            if (partner && partner.data() && partner.data().live) {
                const {game_ids, min_order_size, max_order_size } = partner.data();
                this.setState({
                    numberOfCodes: min_order_size || 1,
                    minOrderSize: min_order_size || 1,
                    maxOrderSize: max_order_size || 150,
                });
                if (game_ids && game_ids.length > 0) {
                    await partner.data().game_ids.forEach(async (gameId) => {
                        const game = await db.collection('games')
                            .doc(gameId).get();
                        if(game.data() && game.data().live) {
                            games.push(game.data());
                        }
                        this.setState({games});
                    });
                }
                this.setCurrentGameToState();
            }

        }
        // db.collection("partners").get().then((querySnapshot) => {
        //     const partners = [];
        //     querySnapshot.forEach((doc) => {
        //         console.log(doc.data());
        //         if( doc.data().email === auth.currentUser.email) {
        //             console.log("Partner for current user: ", doc.data());
        //         }
        //     });
        // }).catch((error) => {
        //    console.log('Error getting partners', error);
        // });
        //
        // db.collection("categories").where("live", "==", true).get().then((querySnapshot) => {
        //     let categories = [];
        //     querySnapshot.forEach((doc) => {
        //         // doc.data() is never undefined for query doc snapshots
        //         categories.push({...doc.data(), _id: doc.id});
        //     });
        //     this.setState({categories})
        // }).catch((error) => {
        //     console.log("Error getting categories: ", error);
        // });
        //
        // db.collection("games").where("live", "==", true).get().then((querySnapshot) => {
        //     let games = [];
        //     querySnapshot.forEach((doc) => {
        //         // doc.data() is never undefined for query doc snapshots
        //         games.push({...doc.data(), _id: doc.id});
        //     });
        //
        //     this.setState({games})
        // }).catch((error) => {
        //     console.log("Error getting games: ", error);
        // });
    };

    setCurrentGameToState = () => {
        const {games} = this.state;
        const {match: {params}} = this.props;
        const {gameId} = params;
        if (gameId) {
            const game = findWhere(games, {id: gameId});
            if (game) {
                this.setState({game});
            }
        }
    }

    componentDidMount() {
        const {auth} = this.props;
        auth.onAuthStateChanged(user => {
            if (user) {
                this.fetchGames();
            }
            console.log('user', user);
        });
    }

    setError = (errorMessage, temp = true) => {
        this.setState({
            error: errorMessage,
        });
        if (temp) {
            setTimeout(() => {
                this.setState({
                    error: null
                });
            }, 5000);
        }
    }

    goToGame = (id) => {
        const {history} = this.props;
        history.push(`/game/${id}`);
    }

    submitOrder = () => {
        const {
            game,
            numberOfCodes,
            minOrderSize,
            maxOrderSize,
        } = this.state;

        if(numberOfCodes < minOrderSize || numberOfCodes > maxOrderSize) {
            Swal.fire({
                title: 'Your order cannot be processed',
                text: `You must order between ${minOrderSize} and ${maxOrderSize} codes. Your current number of codes is: ${numberOfCodes}. Please update your amount of codes to proceed.`
            })
        } else {
            Swal.fire({
                title: "We are processing your order",
                text: "Please be patient, this can take some time.",
                iconColor: '#cb007c',
                showConfirmButton: false,
                closeOnClickOutside: false,
            });
            Swal.showLoading();
            if (game && game.id && numberOfCodes) {
                const {auth, func} = this.props;
                auth.currentUser.getIdToken(true).then((token) => {
                    const createOrderAndSession = firebase.functions().httpsCallable('createOrderAndSession');
                    createOrderAndSession({
                        gameId: game.id,
                        numberOfCodes,
                        clientToken: token,
                    })
                        .then((result) => {
                            console.log(result);
                            if (result && result.data && result.data.sessionId) {
                                this.stripe.redirectToCheckout({sessionId: result.data.sessionId});
                            } else {
                                Swal.fire({
                                    title: "Warning - payment failed",
                                    text: "Error paying",
                                    type: "error",
                                    iconColor: '#ff0000',
                                    showConfirmButton: false,
                                    closeOnClickOutside: false,
                                });
                            }
                            // if (!error && result && result.id) {
                            //     this.stripe.redirectToCheckout({sessionId: result.id});
                            // } else {
                            //     swal({
                            //         type: 'error',
                            //         title: TAPi18n.__('admin_credits_stripe_error'),
                            //         text: TAPi18n.__('admin_credits_stripe_caption'),
                            //     });
                            // }
                        });
                })
            }
        }
    }

    renderGames() {
        const {games} = this.state;

        if (games && games.length > 0) {
            return games.map(({id, name, description, price, image}) => {
                return (<Grid item key={id}>
                    <Card className={"game"} justify={"left"}>
                        <CardHeader title={name} subheader={description}></CardHeader>
                        <CardMedia
                            component="img"
                            alt="gameName"
                            height="160"
                            image={image && image.src ? image.src : "/images/icon-225.png"}
                            title="gameName"
                        />
                        <Button key={id} color="primary"
                                  onClick={() => this.goToGame(id)}>{name} - £{price}</Button>
                    </Card>
                </Grid>);
            });
        } else {
            return <p>NO GAMES TO DISPLAY</p>
        }
    }

    roundToTwoDecimalPrice(num) {
        return Number(+(Math.round(num + "e+2") + "e-2")).toFixed(2);
    }

    render() {
        const {numberOfCodes, game, minOrderSize, maxOrderSize} = this.state;

        if (game) {
            return (
                <div className={"partnerPage"}>
                    <Header/>
                    <h2>Buy codes for {game.name}</h2>
                    <div className={"field"}>
                        <label htmlFor="game">How many codes do you want to buy?</label>
                        <input placeholder="Number of codes" type={"number"} min={minOrderSize} max={maxOrderSize} onChange={(e) => {
                            this.setState({numberOfCodes: e.target.value})
                        }} value={numberOfCodes} id={"game"} name={"game"} required={true}></input>
                    </div>
                    <div className={"total"}>
                        <p>Total: £{this.roundToTwoDecimalPrice(game.price * numberOfCodes)}</p>
                    </div>
                    <Button className="button" variant={"contained"} color={"primary"} onClick={this.submitOrder}>Buy now</Button>
                    <Footer className={"footer"}/>
                </div>
            )
        } else {
            return (
                <div className={"partnerPage"}>
                    <Header/>
                    <Grid container className="games" justify={"center"} spacing={3}>
                        {this.renderGames()}
                    </Grid>
                    <Footer className={"footer"}/>
                </div>
            )
        }
    }
}

export default OrderPage;
