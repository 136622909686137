import React, {Component} from 'react';
import Header from "./components/header";
import './comingSoon.scss'
import {withRouter} from 'react-router-dom';
import Footer from "./components/footer";

class ComingSoon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"disclaimerPage"}>
                <Header nav={false}/>
                <div className={"disclaimerContainer"}>
                    <div className={"disclaimerTitle"}>Coming soon</div>
                    <div className={"disclaimerText"}>
                    </div>
                </div>
                <Footer className={"footer"} />
            </div>
        )
    }
}

export default withRouter(ComingSoon);
