import React from 'react';
import './footer.scss';
import {Link} from 'react-router-dom';

const Footer = ({links = true}) => {
    return (
        <div className={"footer"}>
            <div className={"footerDisclaimer"}>
                {links ? (
                    <div className={"footerList"}>
                        <div className={"footerHeading"}>Service & contact</div>
                        <ul className={"footerLinks"}>
                            <li className={"footerLink"}>
                                <Link to="faq">Frequently Asked Questions</Link>
                            </li>
                            <li className={"footerLink"}>
                                <Link to="terms">Terms of service</Link>
                            </li>
                            <li className={"footerLink"}>
                                <Link to="privacy">Privacy</Link>
                            </li>
                            <li className={"footerLink"}>
                                <Link to="contact">Contact us</Link>
                            </li>
                        </ul>
                    </div>
                ) : null}
            </div>
            <div className={"footerCopyright"}>
                <img className={"footerLogo"} src={"/images/icon-225.png"} />
                <span>© 2021 Escape Room Go - Caught GmbH</span>
            </div>
        </div>
    )
};

export default Footer;
