import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({component: Component, authorized = true, restricted, ...rest}) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            authorized && restricted ?
                <Redirect to="/" />
                : <Component {...props} {...rest} />
        )} />
    );
};

export default PublicRoute;
