import React, {Component} from 'react';
import firebase from 'firebase';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Header from "./components/header";
import {where} from 'underscore';
import './loginPage.scss'
import Footer from "./components/footer";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";

class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            error: null,
        };
    }

    setError = (errorMessage, temp = true) => {
        this.setState({
            error: errorMessage,
        });
        if (temp) {
            setTimeout(() => {
                this.setState({
                    error: null
                });
            }, 5000);
        }
    }

    loginUser = () => {
        const {email, password} = this.state;
        const {auth} = this.props;
        if (email.length < 1 || password.length < 1) {
            this.setError('Enter a email & password');
        } else {
            auth.signInWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    const {history} = this.props;
                    history.push('/games');
                    // ...
                })
                .catch((error) => {
                    this.setError(error.message);
                });
        }
    }

    render() {
        const {error, email, password} = this.state;
        return (
            <div className={"loginPage"}>
                <Header/>
                <Grid>
                    <Card className={"loginCard"} justify={"left"}>
                        <CardContent>
                            <CardHeader title={"Login"}
                                        subheader={"To order codes please login using your Partner credentials"}></CardHeader>
                            <div className={"inputField"}>
                                <label>E-mail
                                    <TextField id="email" value={email} type={"text"} onChange={(e) => {
                                        this.setState({
                                            email: e.target.value,
                                        });
                                    }}/>
                                </label>
                            </div>
                            <div className={"inputField"}>
                                <label>Password
                                    <TextField value={password} type={"password"} onChange={(e) => {
                                        this.setState({
                                            password: e.target.value,
                                        });
                                    }}/>
                                </label>
                            </div>
                            {error ? <div>{error}</div> : null}
                            <Button className={"button"} variant="contained" color="primary" onClick={this.loginUser}>
                                Login
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Footer className={"footer"}/>
            </div>
        )
    }
}

export default LoginPage;
