import React, {Component} from 'react';
import Header from "./components/header";
import './summaryPage.scss'
import {withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import fetch from 'node-fetch';
import Footer from "./components/footer";
import firebase from "firebase";
import Swal from "sweetalert2";
import {EMULATE, ENV, FUNCTIONS_BASE_URL, APP_URLS} from "../config";

class SummaryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {order: null}
    }

    fetchOrder = (orderId) => {
        const {db} = this.props;
        db.collection("orders").doc(orderId).onSnapshot((doc, e) => {
            if (doc.exists) {
                this.setState({order: doc.data()})
                if (doc.data().failed) {
                    this.paymentErrorSwal();
                }
            } else {
                this.unauthorizedSwal();
            }
        }, error => {
            this.unauthorizedSwal();
        })
    };

    unauthorizedSwal() {
        Swal.fire({
            title: "Sorry",
            text: "You are not authorized to view this page.",
            iconColor: '#cb007c',
            showConfirmButton: true,
            confirmButtonText: 'Go back',
            confirmButtonColor: '#cb007c',
            closeOnClickOutside: false,
        }).then(() => {
            this.props.history.push("/")
        });
    }

    paymentErrorSwal() {
        Swal.fire({
            title: "Sorry",
            text: "Your payment was cancelled or failed. You have not been charged.",
            iconColor: '#cb007c',
            showConfirmButton: true,
            confirmButtonText: 'Try again',
            confirmButtonColor: '#cb007c',
            closeOnClickOutside: false,
        }).then(() => {
            this.props.history.push("/games");
        });
    }

    componentDidMount() {
        const {match: {params}, location} = this.props;

        if(location.search.indexOf("error=true") > -1) {
            this.paymentErrorSwal();
        }
        if (params.orderId) {
            this.fetchOrder(params.orderId);
        } else {
            this.setState({error: true})
        }
    }

    renderQRCodes = () => {
        const {order} = this.state;
        return order.caughtGroups.map((group) => (
            <span key={group._id}>
                <img className={"QR"} src={group.QRCode}/>
                <div className={"credentials"}>
                    <div className={"username"}>Username: <b>{group.name}</b></div>
                    <div className={"username"}>Password: <b>{group.password}</b></div>
                </div>
                <div className={"credentials"}>
                    <div className={"username"}>Quick-access code: <b>{group.groupAccessCode}</b></div>
                </div>
            </span>
        ))
    }

    render() {
        const {order} = this.state;

        const {match: {params}, auth, func, location} = this.props;
        const {apple, google} = APP_URLS;

        return (
            <div className={"summaryPage"}>
                <Header/>
                <div className={"summaryContainer"}>
                    <div className={"summaryTitle"}>Your Order Summary</div>
                    {order && order.paid ?
                        <div className={"summaryText"}><p>Success! We've received your payment in good order and have generated your login codes. You can download them below and can always find them back in your Order Overview.</p></div> : null}
                    {!order || !order.caughtGroups ? (
                        <div className={"loadingSpinner"}>
                            <div className="lds-roller">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className={"loadingText"}>
                                {!order ? "Fetching your order - it won't be long..." : !order.paid && !order.failed ? "We are processing your payment..." : !order.caughtGroups ? "We are generating your login codes. Please give us a second (or minute)..." : ""}
                            </div>
                        </div>
                    ) : (<div className={"loginData"}>
                        <div className={"title"}>Login QR codes for your order #{params.orderId}</div>
                        <div className={"validity"}>Every QR code in the PDF can be used for a single login on a single day. It is your own responsibility to track which codes have been used and which have not. <div className={"validUntilDate"}>Your codes are valid until: {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short' }).format(order.valid_until.toDate())}</div></div>
                        {/*{this.renderQRCodes()}*/}
                        <br/>
                        <div className={"downloadCodes"}><Button color={"primary"} variant={"contained"} onClick={() => {
                            auth.currentUser.getIdToken(true).then((token) => {
                                let baseUrl = FUNCTIONS_BASE_URL[ENV];
                                if(EMULATE.func) {
                                    baseUrl = FUNCTIONS_BASE_URL['dev'];
                                }
                                window.open(`${baseUrl}/generateOrderPDF?orderId=${params.orderId}&token=${token}`, '_blank');
                            });
                        }}>Download the credentials as PDF</Button>
                            <Button color={"primary"} variant={"contained"} onClick={() => {
                                auth.currentUser.getIdToken(true).then((token) => {
                                    let baseUrl = FUNCTIONS_BASE_URL[ENV];
                                    if(EMULATE.func) {
                                        baseUrl = FUNCTIONS_BASE_URL['dev'];
                                    }
                                    window.open(`${baseUrl}/generateOrderSheet?orderId=${params.orderId}&token=${token}`, '_blank');
                                });
                            }}>Download the credentials as CSV</Button>
                            <Button color={"primary"} variant={"contained"} onClick={() => {
                                auth.currentUser.getIdToken(true).then((token) => {
                                    let baseUrl = FUNCTIONS_BASE_URL[ENV];
                                    if(EMULATE.func) {
                                        baseUrl = FUNCTIONS_BASE_URL['dev'];
                                    }
                                    window.open(`${baseUrl}/generateOrderSheet?orderId=${params.orderId}&token=${token}&type=xls`, '_blank');
                                });
                            }}>Download the credentials as Excel (XLS)</Button>
                        </div>
                        <div className={"service"}>Having trouble signing or do you have any other questions? Please contact us at <a
                            href={"mailto:info@bartswandelquiz.nl"}>partners@escaperoomgo.co.uk</a> and refer to your Order ID: #{params.orderId}</div>
                    </div>)}
                    <div className={"appContainer"}>
                        <div className={"appTitle"}>Download the app</div>
                        <div className={"appIm ages"}>
                            <div className={"appIcon"}>
                                <img src={'/images/icon-225.png'}/>
                            </div>
                            <div className={"appDownloads"}>
                                <a href={apple} target="_blank"><img
                                    src={'/images/Apple_download.svg'}/></a>
                                <a href={google} target="_blank"><img
                                    src={'/images/Google_download.svg'}/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer className={"footer"}/>
            </div>
        )
    }
}

export default withRouter(SummaryPage);
