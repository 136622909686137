import React, {Component} from 'react';
import Header from "./components/header";
import { where } from 'underscore';
import './homePage.scss'
import Footer from "./components/footer";
import {Link} from "react-router-dom";

class HomePage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { auth } = this.props;

        return (
            <div className={"homePage"}>
                <Header />

                <Footer className={"footer"} />
            </div>
        )
    }
}

export default HomePage
