import React, {Component} from 'react';
import Header from "./components/header";
import firebase from "firebase";
import {withRouter} from 'react-router-dom';
import {where, findWhere} from 'underscore';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

import './ordersOverviewPage.scss'
import Footer from "./components/footer";
import Swal from "sweetalert2";
import Select from 'react-select';

import 'react-accessible-accordion/dist/fancy-example.css';

import {EMULATE, ENV, FUNCTIONS_BASE_URL } from "../config";

const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
});

class OrdersOverviewPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
        }
    }

    fetchOrders = async () => {
        const {auth, db} = this.props;
        const user = auth.currentUser;
        if (user !== null) {
            const orders = [];
            await db.collection('orders')
                .orderBy("date", "desc")
                .where('user', '==', user.uid)
                .get()
                .then((orderSnapShot) => {
                    orderSnapShot.forEach((order) => {
                        orders.push({id: order.id, ...order.data()});
                    })
                })
                .catch((error) => {
                    console.log('error',error);
                })
            this.setState({
                orders,
            });

        }
    };

    componentDidMount() {
        const {auth} = this.props;
        auth.onAuthStateChanged(user => {
            if (user) {
                this.fetchOrders();
            }
        });
    }

    getOrderStatus(order) {
        if(order.caughtGroups?.length > 0) {
            return 'Complete';
        } else if (order.paymentId) {
            return 'Paid';
        } else if (order.generating) {
            return 'Generating Codes';
        }  else {
            return 'Pending';
        }
    }

    renderOrders() {
        const {orders} = this.state;
        const {auth} = this.props;

        return orders.map((order) => (
            <TableRow key={order.id}>
                <TableCell component="th" scope="row">
                    {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short', timeStyle: 'short' }).format(order.date.toDate())}
                </TableCell>
                <TableCell component="th" scope="row">
                    {order.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {new Intl.DateTimeFormat('en-GB', { dateStyle: 'short' }).format(order.valid_until.toDate())}
                </TableCell>
                <TableCell align="right">{order.gameNameAtTimeOfTransaction}</TableCell>
                <TableCell align="right">{order.numberOfCodes}</TableCell>
                <TableCell align="right">£{order.transactionValue}</TableCell>
                <TableCell align="right"><Chip label={this.getOrderStatus(order)} /></TableCell>
                <TableCell align="right">{order.caughtGroups?.length > 0 ?
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <Chip color={"primary"} label={"Download PDF"} onClick={() => {
                        auth.currentUser.getIdToken(true).then((token) => {
                            let baseUrl = FUNCTIONS_BASE_URL[ENV];
                            if(EMULATE.func) {
                                baseUrl = FUNCTIONS_BASE_URL['dev'];
                            }
                            window.open(`${baseUrl}/generateOrderPDF?orderId=${order.id}&token=${token}`, '_blank');
                        });
                    }} style={{ marginBottom: 3 }}/>
                    <Chip color={"primary"} label={"Download CSV"} onClick={() => {
                        auth.currentUser.getIdToken(true).then((token) => {
                            let baseUrl = FUNCTIONS_BASE_URL[ENV];
                            if(EMULATE.func) {
                                baseUrl = FUNCTIONS_BASE_URL['dev'];
                            }
                            window.open(`${baseUrl}/generateOrderSheet?orderId=${order.id}&token=${token}`, '_blank');
                        });
                    }} style={{ marginBottom: 3 }} />
                    <Chip color={"primary"} label={"Download XLS"} onClick={() => {
                        auth.currentUser.getIdToken(true).then((token) => {
                            let baseUrl = FUNCTIONS_BASE_URL[ENV];
                            if(EMULATE.func) {
                                baseUrl = FUNCTIONS_BASE_URL['dev'];
                            }
                            window.open(`${baseUrl}/generateOrderSheet?orderId=${order.id}&token=${token}&type=xls`, '_blank');
                        });
                    }} style={{ marginBottom: 3 }} />
                    </span>
                    : null
                }</TableCell>
            </TableRow>
        ));
    }

    render() {

        return (
            <div className={"orderPage"}>
                <Header/>
                <TableContainer component={Paper}>
                    <Table className={"table"} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell># ID</TableCell>
                                <TableCell>Valid until</TableCell>
                                <TableCell align="right">Game name</TableCell>
                                <TableCell align="right">Number of codes</TableCell>
                                <TableCell align="right">Price (ex. VAT)</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Download</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.renderOrders()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Footer className={"footer"}/>
            </div>
        )
    }
}

export default withRouter(OrdersOverviewPage);
