import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import OrderList from './OrderList';
import OrderEdit from './OrderEdit';

export default {
    list: OrderList,
    edit: OrderEdit,
    icon: ShoppingCartIcon,
};
