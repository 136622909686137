import React from 'react';
import { Datagrid, DateField, EditButton, List, TextField, ReferenceField, BooleanField, FunctionField, Filter, TextInput} from 'react-admin';

const OrderFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn/>
        <TextInput label="Partner Email" source="email" alwaysOn/>
    </Filter>
);

const getOrderStatus = (order) => {
    if(order.caughtGroups?.length > 0) {
        return 'Complete';
    } else if (order.paymentId) {
        return 'Paid';
    } else if (order.generating) {
        return 'Generating Codes';
    } else {
        return 'Pending';
    }
}

const OrderList = (props: any) => (
    <List {...props} filters={<OrderFilter />} perPage={25} bulkActionButtons={false} sort={{ field: 'date', order: 'DEC' }}>
        <Datagrid>
            <DateField source="date" />
            <ReferenceField label="Partner" source="user" reference="partners">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Game" source="gameId" reference="games">
                <TextField source="name" />
            </ReferenceField>
            <TextField label="Order ID" source="id" />
            <TextField label="No. of cdes" source="numberOfCodes" />
            <TextField label="Price p/Code" source="pricePerCode" />
            <TextField label="Value" source="transactionValue" />a
            <FunctionField label="Status" render={record => getOrderStatus(record)} />
            <EditButton label="Edit" />
        </Datagrid>
    </List>
);

export default OrderList;
