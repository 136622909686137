import React from 'react';
import { Datagrid, EditButton, List, TextField, DeleteButton, ReferenceField, ChipField } from 'react-admin';

const GameList = (props: any) => (
    <List {...props} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid>
            <TextField label="Naam" source="name" />
            <ReferenceField label="Categorie" reference="categories" source="cat">
                <ChipField source="name" />
            </ReferenceField>
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default GameList;
