import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import orders from '../Orders';
import categories from '../Categories';
import partners from '../Partners';
import games from '../Games';
import faq from '../Faq';

import { AppState } from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
    });
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            {' '}
            <DashboardMenuItem sidebarIsOpen={open}/>
            <MenuItemLink
                to={`/orders`}
                primaryText={translate(`resources.orders.name`, {
                    smart_count: 2,
                })}
                leftIcon={<orders.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/categories`}
                primaryText={translate(`resources.categories.name`, {
                    smart_count: 2,
                })}
                leftIcon={<categories.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/partners`}
                primaryText={translate(`resources.partners.name`, {
                    smart_count: 2,
                })}
                leftIcon={<partners.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/games`}
                primaryText={translate(`resources.games.name`, {
                    smart_count: 2,
                })}
                leftIcon={<games.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/faq`}
                primaryText={translate(`resources.faq.name`, {
                    smart_count: 2,
                })}
                leftIcon={<faq.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            {isXSmall && logout}
        </div>
    );
};

export default Menu;
