import UserIcon from '@material-ui/icons/AccountBox';

import PartnerList from './PartnerList';
import PartnerCreate from './PartnerCreate';
import PartnerEdit from './PartnerEdit';

export default {
    create: PartnerCreate,
    list: PartnerList,
    edit: PartnerEdit,
    icon: UserIcon,
};
