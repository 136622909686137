import React from 'react';
import { Datagrid, EditButton, DeleteButton, List, TextField, NumberField, BooleanField } from 'react-admin';

const FaqList = (props: any) => (
    <List {...props} perPage={25} bulkActionButtons={false} sort={{ field: 'sort', order: 'ASC' }}>
        <Datagrid>
            <TextField source="question" />
            <NumberField source="sort" />
            <BooleanField source="live" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default FaqList;
