import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import FaqList from './FaqList';
import FaqCreate from './FaqCreate';
import FaqEdit from './FaqEdit';

export default {
    create: FaqCreate,
    list: FaqList,
    edit: FaqEdit,
    icon: QuestionAnswerIcon,
};
