import React, { FC } from 'react';
import {
    Datagrid,
    Create,
    SaveButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    BooleanInput,
    TextInput,
    useTranslate,
    ReferenceArrayInput,
    NumberInput,
    SelectArrayInput,
    Toolbar,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { FieldProps, Category } from '../types';

const FileTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.faq.name', { smart_count: 1 })} &quot;
            {record.question}&quot;
        </span>
    ) : null;
};

const FileEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton label={"Toevoegen"} />
    </Toolbar>
);

const FaqCreate = (props: any) => (
    <Create title={<FileTitle />} {...props}>
        <SimpleForm toolbar={<FileEditToolbar />}>
            <TextInput fullWidth={true} label="Vraag" source="question" />
            <RichTextInput fullWidth={true} label="Antwoord" source="answer" />
            <NumberInput label="Sorteer" source="sort"/>
            <BooleanInput label="Live" source="live"/>
        </SimpleForm>
    </Create>
);

export default FaqCreate;
