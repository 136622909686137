import React, { FC } from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    BooleanInput,
    TextInput,
    TextField,
    NumberInput,
    useTranslate,
    ReferenceArrayInput,
    SelectArrayInput,
    Toolbar,
    SaveButton,
    DeleteButton, ImageField, ImageInput, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { FieldProps, Category } from '../types';

const FileTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.categories.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

const FileEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
        <DeleteButton />
    </Toolbar>
);

const validatePartnerCreation = (values) => {
    const errors = {} as any;
    const {name, email, password, min_order_size, max_order_size} = values;
    if (name.length < 5) {
        errors.name = 'Name is too short';
    }

    if (email.length < 5) {
        errors.email = 'Invalid E-mail';
    }
    if (password.length < 8) {
        errors.password = 'Password too short';
    }

    if(max_order_size < 1) {
        errors.min_order_size = 'Min order size cannot be smaller than 1';
    }

    if(max_order_size > 150) {
        errors.max_order_size = 'Max order size cannot be larger than 150';
    }

    if(min_order_size > max_order_size) {
        errors.min_order_size = 'Min order size cannot be larger than max order size';
    }

    return errors;
}

const PartnerEdit = (props: any) => (
    <Edit title={<FileTitle />} {...props}>
        <SimpleForm toolbar={<FileEditToolbar/>} validate={validatePartnerCreation}>
            <TextField fullWidth={true} label="Name" source="name"/>
            <TextField fullWidth={true} label="E-mail" source="email"/>
            <TextField fullWidth={true} label="Password" source="password"/>
            <TextField fullWidth={true} label="# ID" source="id"/>
            <NumberInput source="min_order_size" step={1} min={1} />
            <NumberInput source="max_order_size" step={1} max={150} />
            <SelectInput source="code_validity" multiple={false} choices={[{
                id: '6',
                name: '6 Months',
            }, {
                id: '12',
                name: '12 Months',
            }]} initialValue={6} />
            <ImageInput source="logo" label="Logo" accept="image/png, image/jpeg" placeholder={<p>Drop your logo (JPG or PNG) here</p>}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <BooleanInput label="Charge VAT" source="chargeVAT"/>
            <BooleanInput label="Live" source="live"/>

            <ReferenceArrayInput label="Games" source="game_ids" reference="games">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export default PartnerEdit;
