import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import './header.scss';

const Header = ({location, nav = true}) => {
    const {pathname} = location;


    return (
        <div className={"header"}>
            <img className={"logo"} src={"/images/logo.svg"} />
            <div className={"imageBox"}>
                {/*{ nav ? (*/}
                {/*        <Link to="/">*/}
                {/*            <img className={"headerImage"} src={"/images/header.png"} />*/}
                {/*        </Link>*/}
                {/*    ) : (*/}
                {/*        <img className={"headerImage"} src={"/images/header.png"} />*/}
                {/*    )}*/}
            </div>
            { nav ? (<div className={"nav"}>
                <Link to="/games" href={"/games"} className={"navItem" + (pathname === "/games" ? ' active' : '')}>Order codes</Link>
                <Link to="/your-orders" href={"/your-orders"} className={"navItem" + (pathname === "/your-orders" ? ' active' : '')}>Your orders</Link>
                <Link to="/faq" className={"navItem" + (pathname === "/faq" ? ' active' : '')}>Frequently Asked Questions</Link>
                <Link to="/logout" className={"navItem" + (pathname === "/logout" ? ' active' : '')}>Sign out</Link>
            </div>) : null}
        </div>
    )
};

export default withRouter(Header);
