import React, { FC } from 'react';
import {
    Datagrid,
    Create,
    SimpleForm,
    BooleanInput,
    TextInput,
    useTranslate,
    ReferenceArrayInput,
    SelectArrayInput,
    Toolbar,
    SaveButton
} from 'react-admin';

import { FieldProps, Category } from '../types';

const FileTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.faq.name', { smart_count: 1 })} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

const FileEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const CategoryCreate = (props: any) => (
    <Create title={<FileTitle />} {...props}>
        <SimpleForm toolbar={<FileEditToolbar />}>
            <TextInput fullWidth={true} label="Naam" source="name" />
            <TextInput fullWidth={true} label="Afkorting" source="short" />
            <BooleanInput label="Live" source="live"/>
        </SimpleForm>
    </Create>
);

export default CategoryCreate;
