import React, { FC } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, Query, Error, Loading } from 'react-admin';
import CardWithIcon from './CardWithIcon';

const useStyles = makeStyles({
    media: {
        height: '18em',
    },
});
const Welcome: FC = () => {
    const payload = {
        pagination: { page: 1, perPage: 1000000},
        sort: { field: 'date', order: 'ASC' },
        filter: {paid: true}
    };
    const payloadV = {
        pagination: { page: 1, perPage: 10 },
        filter: {expired: true}
    };
    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Statistieken
                </Typography>
                <Query type='getList' resource='orders' payload={payload}>
                    {({ data, total, loading, error }) => {
                        if (loading) { return <Loading />; }
                        if (error) { return <Error error={error}/>; }
                        let codes = 0;
                        data.forEach((order) => {
                            codes += Number(order.playerCount)
                        });
                        return (
                            <div>
                                <CardWithIcon
                                    to="/orders"
                                    icon={ShoppingCartIcon}
                                    title={"Betaalde Bestellingen"}
                                    subtitle={total}
                                />
                                <CardWithIcon
                                    to="/orders"
                                    icon={SportsEsportsIcon}
                                    title={"Aantal inlogcodes"}
                                    subtitle={codes}
                                />
                            </div>
                        );
                    }}
                </Query>
            </CardContent>

        </Card>
    );
};

export default Welcome;
