import React, { FC } from 'react';
import {
    Create,
    EditButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    TextInput,
    useTranslate,
    SaveButton,
    Toolbar,
    BooleanInput,
    NumberInput,
    ReferenceInput,
    SelectInput, ImageField, ImageInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { FieldProps, Category } from '../types';

const GameTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.games.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

const GameCreateToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const GameCreate = (props: any) => (
    <Create title={<GameTitle />} {...props}>
        <SimpleForm toolbar={<GameCreateToolbar />}>
            <TextInput fullWidth={true} label="Name" source="name" />
            <TextInput label="Description" source="description" />
            <TextInput fullWidth={true} label="Caught Location-ID" source="caughtId" />
            <NumberInput label={"Price (£)"} source={"price"}/>
            <ReferenceInput label="Category" source="cat" reference="categories">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ImageInput source="image" label="Image" accept="image/png, image/jpeg" placeholder={<p>Drop your image for this game (JPG or PNG) here</p>}>
                <ImageField source="src" title="title" />
            </ImageInput>
            <BooleanInput label="Live" source="live"/>
        </SimpleForm>
    </Create>
);

export default GameCreate;
