import { TranslationMessages } from 'ra-core';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    resources: {
       orders: {
           name: "Orders",
       },
        categories: {
            name: "Categories"
        },
        partners: {
            name: "Partners"
        },
        games: {
           name: "Games"
       },
        faq: {
            name: "FAQ"
        },
    },
};

export default customEnglishMessages;
